<template>
  <div @click="test" class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มวันหมดอายุของสินค้า
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <div class="row mb-3 d-flex justify-content-center">
          <label for="input1" class="form-label col-sm-10 required"
            >ค้นหาสินค้า</label
          >
          <div class="col-sm-5">
            <!-- <input
              v-model="searchProduct"
              type="text"
              class="form-control"
              id="input1"
              required
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            /> -->
            <input
              v-model="searchProduct"
              type="text"
              class="form-control"
              id="input1"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>

          <div class="col-sm-3">
            <div
              class="form-check form-check-inline form-check-custom form-check-solid required"
            >
              <input
                v-model="searchBy"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="code"
                id="flexRadioChecked"
              />

              <label
                class="form-check-label m-4"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >Item no.</label
              >
              <input
                v-model="searchBy"
                class="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                value="barcode"
                id="flexRadioChecked2"
                checked="checked"
              />
              <label
                class="form-check-label"
                for="flexRadioChecked"
                :style="isSubmit ? 'color: black' : ''"
                >Barcode</label
              >
            </div>
          </div>

          <div class="col-sm-12 col-md-2">
            <button
              type="button"
              class="btn btn-sm btn-light-primary"
              style="font-size: 14px; font-weight: bold; width: 100%"
              width="100%"
              @click="search"
            >
              ค้นหา
            </button>
          </div>
        </div>

        <!-- -->
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Item no.</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.product_code"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Item name</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.product_name"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >Barcode</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.product_barcode"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center text-start">
          <div class="col-sm-5">
            <label class="form-label">On Hand</label>
            <input
              v-model="form.product_bring_qty"
              disabled
              type="text"
              class="form-control mb-4"
              id="inputGroupFile01"
            />
          </div>
          <div class="col-sm-5">
            <label class="form-label">In Transit</label>
            <input
              disabled
              type="text"
              class="form-control mb-4"
              id="inputGroupFile01"
            />
          </div>
        </div>

        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10"
            >POG</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.product_pog"
              type="text"
              class="form-control"
              id="validationTooltip01"
              disabled
            />
          </div>
        </div>

        <!-- -->
        <div class="row mb-4 d-flex justify-content-center">
          <label for="validationTooltip01" class="form-label col-sm-10 required"
            >วันหมดอายุสินค้า</label
          >
          <div class="col-sm-10">
            <!-- <input
              v-model="form.product_expire"
              :type="isTypeDate"
              ref="date"
              class="form-control"
              id="receiveDate"
              name="receiveDate"
              maxlength="10"
              @focus="!typingDate ? setDateToDefualt() : ''"
              @focusout="handleFocusOut(form.product_expire)"
              @blur="typingDate ? formatNewDate() : ''"
              @input="
                typingDate
                  ? typeDateChange(form.product_expire)
                  : dateChange(form.product_expire)
              "
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
              @click="openDatepicker()"
              required
            /> -->
            <a-date-picker
              v-if="isDatepicker"
              class="form-control ant-date"
              :locale="locale"
              v-model:value="dateExp"
              format="DD/MM/YYYY"
              inputReadOnly
            />

            <input
              v-if="typingDate"
              v-model="dateExp"
              class="form-control"
              type="text"
              maxlength="10"
              minlength="10"
              required
              @keyup.delete="dateExp = ''"
              @input="typeDateChange(dateExp)"
              :style="
                dateExp.length === 10 ? '' : !ccsOnCreate ? '' : ccsSelect
              "
            />

            <div v-if="ccsOnCreate" style="color: red" class="text-end">
              กรุณากรอก และกรอกให้ตรง รูปแบบ : วัน/เดือน/ปี
            </div>

            <div class="mt-3">
              <input
                v-model="typingDate"
                style="cursor: pointer"
                type="checkbox"
                @change="typingDateChange(typingDate)"
                class="form-checkbox mt-4"
              />
              <label class="form-check-label ms-2">กรอกวันที่เอง</label>
            </div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";

export default {
  setup() {
    document.title = "CHOMTHANA | เพิ่มวันหมดอายุของสินค้า";
    return {
      locale,
    };
  },

  data: () => ({
    loadingUpdate: false,
    searchProduct: "",
    isSubmit: false,
    searchBy: "barcode",
    isDatepicker: false,

    typingDate: true,
    isTypeDate: "date",

    isDateChange: false,

    validDay: "",
    validMonth: "",

    dateExp: "",
    form: {
      //new
      selectMainProduct: "",
      selectSubProduct: "",

      product_name: "",
      product_desc: "",
      product_barcode: "",
      product_pog: "",
      product_cost_price: "",
      product_wholesale_price: "",
      product_retail_price: "",
      product_promotion_price: "",
      product_min_stock: "",
      product_max_stock: "",
      product_bring_qty: "",
      status: "0",
      approved_by: "",
      product_expire: "",

      //old
      itemNo: "",
      itemDetail: "",
      barcode: "",
      accountingPeriod: "",
      docNumber: "",
      amount: "",
      pricePerUnit: "",
      discountPerUnit: "",
      vat: "",
      receiveDate: "",
      consignee: "",
      warehouseTo: "",
    },
    ccsOnCreate: false,
    ccsSelect: `border-color: red;background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f1416c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f1416c' stroke='none'/%3e%3c/svg%3e")`,
  }),

  methods: {
    setDateBeforeSubmit() {
      // this.form.product_expire = moment(this.form.product_expire)
      //   .add(-543, "year")
      //   .format("YYYY-MM-DD");
      if (!this.typingDate) {
        this.form.product_expire = moment(this.dateExp.$d).format("YYYY-MM-DD");
      } else {
        const year = this.dateExp.substring(6, 10);
        const month = this.dateExp.substring(3, 5);
        const day = this.dateExp.substring(0, 2);
        this.form.product_expire = `${year}-${month}-${day}`;
        console.log(this.form.product_expire);
      }
    },
    async submit() {
      let updateResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loadingUpdate = true;

        this.setDateBeforeSubmit();

        updateResponse = await whApi.product.updateExp(this.form);
        if (updateResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/WH5",
              query: {},
            });
          });
          this.loadingUpdate = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "แก้ไขไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadingUpdate = false;
        }
      }
    },
    checkFormIsEmpty() {
      !this.dateExp
        ? (this.isValid = false)
        : this.dateExp.length < 10
        ? (this.isValid = false)
        : !this.form.product_code
        ? (this.isValid = false)
        : (this.isValid = true);

      !this.dateExp ? (this.ccsOnCreate = true) : (this.ccsOnCreate = false);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },

    //search
    async search() {
      const companyId = localStorage.getItem("companyId");
      if (this.searchBy === "code") {
        await this.searchByCode(companyId, this.searchProduct);
      }
      if (this.searchBy === "barcode") {
        await this.searchByBarcode(companyId, this.searchProduct);
      }
    },
    async searchByCode(companyId, productCode) {
      let responseData = [];
      try {
        responseData = await whApi.product.searchByCode(companyId, productCode);
      } catch (error) {
        console.log(error);
      }
      if (responseData.data.length > 0) {
        if (responseData.data[0].status !== "1") {
          Swal.fire({
            icon: "warning",
            title: `ค้นหาสำเร็จ !`,
            text: `สินค้า Code : ${productCode} ยังไม่ได้รับการอนุมัติ`,
            confirmButtonText: "ตกลง",
          });
        } else {
          this.form = {
            ...this.form,
            ...responseData.data[0],
          };
          this.setDateExpire();
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Item no. : ${productCode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    async searchByBarcode(companyId, productBarcode) {
      let responseData = [];
      try {
        responseData = await whApi.product.searchByBarcode(
          companyId,
          productBarcode
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.data.length > 0) {
        if (responseData.data[0].status !== "1") {
          Swal.fire({
            icon: "warning",
            title: `ค้นหาสำเร็จ !`,
            text: `สินค้า Barcode : ${productBarcode} ยังไม่ได้รับการอนุมัติ`,
            confirmButtonText: "ตกลง",
          });
        } else {
          this.form = {
            ...this.form,
            ...responseData.data[0],
          };
          this.setDateExpire();
        }
      } else {
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Barcode : ${productBarcode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },

    //date
    typingDateChange(val) {
      if (val) {
        this.dateExp = "";
        this.form.product_expire = "";
        this.isDatepicker = false;
      } else {
        this.dateExp = "";
        this.form.product_expire = "";
        setTimeout(() => {
          this.isDatepicker = true;
        }, 100);
      }
    },

    setDateExpire() {
      if (this.form.product_expire) {
        this.form.product_expire = moment(this.form.product_expire)
          .add(543, "year")
          .format("YYYY-MM-DD");
      }
    },
    openDatepicker() {
      this.isDateChange = false;
      document.getElementById("receiveDate").click();
    },

    dateChange(val) {
      console.log(val, "ss");
    },
    typeDateChange(val) {
      if (val) {
        this.ccsOnCreate = true;
        if (val.length <= 2 && parseInt(val) <= 31) {
          this.validDay = val;
        } else if (val.length === 5) {
          console.log("length5");
          this.dateExp = val + "/";
        }

        if (val.length <= 2 && parseInt(val) > 31) {
          this.dateExp = "31/";
          this.validDay = "31";
        } else if (val.length === 2) {
          this.dateExp = val + "/";
        } else if (val.length >= 4 && val.length <= 5) {
          if (parseInt(val.substring(3, 5)) > 12) {
            console.log("substring");
            this.dateExp = `${this.validDay}/12/`;
          }
        }

        if (val.length === 10) {
          this.ccsOnCreate = false;
        }
      } else {
        this.ccsOnCreate = false;
      }
    },
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
